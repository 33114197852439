cs.ns('app.ui.trait.root.websocketservices')
app.ui.trait.root.websocketservices.ctrl = cs.trait({
    dynamics: {
        socket:               null,
        socketReconnectTimer: null
    },
    protos:   {

        setup () {
            this.base()
            this.connectSocket()

            this.observeOwnModel('state:webSocketConnected', (ev, state) => {
                if (state)
                    this.publishEventToParent('webSocketConnected')
                else
                    this.publishEventToParent('webSocketDisconnected')
            })
        },

        connectSocket () {
            this.base()
            if (!this.socket) {
                const loc             = window.location
                let port              = loc.port ? ':' + loc.port : ''
                this.socket           = new WebSocket(`${(loc.protocol === 'https:') ? 'wss' : 'ws'}://${loc.hostname}${port}/sv/events`);
                this.socket.onopen    = this.onWebSocketOpen.bind(this)
                this.socket.onclose   = this.onWebSocketClose.bind(this)
                this.socket.onmessage = this.onWebSocketMessage.bind(this)
            }
        },

        disconnectSocket (allowReconnect) {
            this.base()
            if (!allowReconnect) this.stopSocketReconnection()
            if (!this.socket) return;
            if (this.model.value('state:webSocketConnected')) {
                this.socket.close();
                this.model.value('state:webSocketConnected', false);
            }
            this.socket = null;
        },

        onWebSocketOpen () {
            this.base()
            this.model.value('state:webSocketConnected', true)
        },

        onWebSocketClose () {
            this.base()
            this.disconnectSocket()
            this.startSocketReconnection()
        },

        onWebSocketMessage (message) {
            this.base(message)
        },

        startSocketReconnection () {
            this.base()
            if (!this.socketReconnectTimer)
                this.socketReconnectTimer = window.setTimeout(() => {
                    this.stopSocketReconnection()
                    this.reconnectSocket()
                }, 5000)
        },

        stopSocketReconnection () {
            this.base()
            this.socketReconnectTimer = window.clearTimeout(this.socketReconnectTimer)
        },

        reconnectSocket () {
            this.base()
            if (this.model.value('state:webSocketConnected'))
                this.disconnectSocket(true)
            this.connectSocket()
        }

    }
})